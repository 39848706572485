import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Distinctions`}</h4>
    <p><strong parentName="p">{`Elemental Scion of the Primal Water`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Rysha was born as an Elemental Scion of the Primal Spirit of Water, giving her a unique link to the Primal Water, changing her physical appearance and giving her control over the element of water.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Waterwall`}</em>{`: Spend a PP to create a wall of water that moves to act as a shield for you or an ally, creating a `}<inlineCode parentName="li">{`jsx:<D8/>`}</inlineCode>{` shared asset`}</li>
    </ul>
    <p><strong parentName="p">{`Curious Aristocrat`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Rysha comes from a long line of aristocrats that rule as a part of the `}<a parentName="p" {...{
        "href": "/High%20Authority",
        "title": "High Authority"
      }}>{`High Authority`}</a>{` in `}<a parentName="p" {...{
        "href": "/Tharsis",
        "title": "Tharsis"
      }}>{`Tharsis`}</a>{`. She has a drive to know all things about the world, and her curiosity gets her in trouble more often than not.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <p><strong parentName="p">{`Armchair Scientist`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Rysha took a liking to Science at a young age, and it has always been a passion for her.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <h4>{`Attributes`}</h4>
    <p><em parentName="p">{`Strength`}</em>{` `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
`}<em parentName="p">{`Dexterity`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Intellect`}</em>{` `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode>{`
`}<em parentName="p">{`Will`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Awareness`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Conviction`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode></p>
    <h4>{`Values`}</h4>
    <p><em parentName="p">{`Responsibility`}</em>{` `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode>{`
A leader is someone who understands the responsibility that comes with their freedom.
`}<em parentName="p">{`Power`}</em>{` `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
People concerned with power usually don't have true power.
`}<em parentName="p">{`Honesty`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
I always stand up for what I believe.
`}<em parentName="p">{`Self`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
In order to rule others, first one must rule themselves.
`}<em parentName="p">{`Knowledge`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
There is always good in knowledge---and evil in ignorance.
`}<em parentName="p">{`Altruism`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
A leader is as strong as her weakest subject.`}</p>
    <h4>{`Luminous Magic`}</h4>
    <p><strong parentName="p">{`Convictions of Gravity`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Rysha has chosen to focus her studies as an Acronist on `}<em parentName="p">{`Gravity Manipulation`}</em>{`. She excels at manifesting Convictions related to changing and manipulating the laws of gravity, both for herself, objects, and other creatures.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Fixed Truth`}</em>{`: When manifesting a conviction related to Gravitational Manipuation, spend a PP to create a `}<inlineCode parentName="li">{`jsx:<D8/>`}</inlineCode>{` asset out of the manifestation.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      